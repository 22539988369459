import {shapedAccountData, shapedMedia, shapedReblogStatusData, shapedStatus, timeLineResultData} from "../../../types";

function transformResponseData (response: any): timeLineResultData {
    return {
        newest_id: response.newest_id,
        oldest_id: response.oldest_id,
        data: transformPostData(response.data)
    }
}

function transformPostData(data: any[]): shapedStatus[] {
    return data.map(item => {
        return {
            sns: "neo",
            post_id: item.post_id,
            account: transformAccountData(item.account),
            content: item.content,
            visibility: item.visibility,
            reply_destination_id: item.reply_destination_id ? item.reply_destination_id: null,
            reply: item.reply,
            sensitive: item.sensitive,
            introduction_sentence: item.introduction_sentence,
            favourited: item.favourited,
            reblog: item.reblog,
            reblog_content: item.reblog_content ? transformReblogData(item.reblog_content) : null,
            media: item.media ? transformMediaData(item.media) : null,
            client_name: item.client_data.client_name,
            create_at: item.create_at,
            update_at: item.update_at,
        };
    });
}

function transformWebSocketData(data: any): shapedStatus {
    return {
        sns: "neo",
        post_id: data.post_id,
        account: transformAccountData(data.account),
        content: data.content,
        visibility: data.visibility,
        reply_destination_id: data.reply_destination_id ? data.reply_destination_id: null,
        reply: data.reply,
        sensitive: data.sensitive,
        introduction_sentence: data.introduction_sentence,
        favourited: data.favourited,
        reblog: data.reblog,
        reblog_content: data.reblog_content ? transformReblogData(data.reblog_content) : null,
        media: data.media ? transformMediaData(data.media) : null,
        client_name: data.client_data.client_name,
        create_at: data.create_at,
        update_at: data.update_at,
    };
}

function transformAccountData(data: any): shapedAccountData {
    return {
        user_id: data.user_id,
        username: data.username,
        acct: `${data.username}`,
        display_name: data.display_name ? data.display_name: null,
        bio: data.bio ? data.bio: null,
        icon_url: data.icon_url,
        header_url: data.header_url ? data.header_url: null,
    }
}

function transformMediaData(data: any[]): shapedMedia[] {
    return data.map(item => {
        return {
            media_id: item.media_id,
            user_id: item.user_id,
            url: item.url,
            visibility: item.visibility,
            data_type: item.data_type,
        }
    })
}

function transformReblogData(data: any): shapedReblogStatusData {
    return {
        post_id: data.post_id,
        account: transformAccountData(data.account),
        content: data.content,
        visibility: data.visibility,
        reply_destination_id: data.reply_destination_id ? data.reply_destination_id: null,
        reply: data.reply,
        sensitive: data.sensitive,
        introduction_sentence: data.introduction_sentence,
        favourited: data.favourited,
        media: data.media ? transformMediaData(data.media) : null,
        //@ts-ignore
        client_name: data.client_data.client_name,
        create_at: data.created_at,
        update_at: data.created_at,
    }
}

function transformResponseDataAstarte (data: any[]):shapedStatus[] {
    return data.map(item => {
        return {
            sns: "mastodon",
            post_id: item.id,
            account: transformAccountDataAstarte(item.account),
            content: item.content,
            visibility: item.visibility,
            reply_destination_id: null,
            reply: false,
            sensitive: false,
            introduction_sentence: item.spoiler_text,
            favourited: item.favourited,
            reblog: item.reblog ? true: false,
            reblog_content: item.reblog ? transformReblogDataAstarte(item.reblog) : null,
            media: item.media_attachments ? transformMediaDataAstarte(item.media_attachments, item.account.id): null,
            client_name: `闇の中`,
            create_at: item.created_at,
            update_at: item.created_at,
        };
    });
}

function transformWebSocketDataAstarte (data: any):shapedStatus {
    return {
        sns: "mastodon",
        post_id: data.id,
        account: transformAccountDataAstarte(data.account),
        content: data.content,
        visibility: data.visibility,
        reply_destination_id: null,
        reply: false,
        sensitive: false,
        introduction_sentence: data.spoiler_text,
        favourited: data.favourited,
        reblog: data.reblog ? true: false,
        reblog_content: data.reblog ? transformReblogDataAstarte(data.reblog) : null,
        media: data.media_attachments ? transformMediaDataAstarte(data.media_attachments, data.account.id): null,
        client_name: `闇の中`,
        create_at: data.created_at,
        update_at: data.created_at,
    };
}

function transformAccountDataAstarte(data: any): shapedAccountData {
    return {
        user_id: data.id,
        username: data.username,
        acct: data.acct,
        display_name:  data.display_name ? data.display_name: null,
        bio: data.note ? data.note: null,
        icon_url: data.avatar ? data.avatar: "https://media-astarte.global.ssl.fastly.net/media_attachments/files/107/491/801/590/183/180/original/fdeee3aadeb9298b.png",
        header_url: data.header ? data.header: null
    }
}

function transformMediaDataAstarte(data: any[], id: string): shapedMedia[] {
    return data.map(item => {
        return {
            media_id: item.id,
            user_id: id,
            url: item.url,
            visibility: `public`,
            data_type: item.type,
        }
    })
}

function transformReblogDataAstarte(data: any): shapedReblogStatusData {
    return {
        post_id: data.id,
        account: transformAccountDataAstarte(data.account),
        content: data.content,
        visibility: data.visibility,
        reply_destination_id: null,
        reply: false,
        sensitive: false,
        introduction_sentence: data.spoiler_text,
        favourited: data.favourited,
        media: data.media_attachments ? transformMediaDataAstarte(data.media_attachments, data.account.id): null,
        client_name: `闇の中`,
        create_at: data.created_at,
        update_at: data.created_at,
    }
}

export {
    transformResponseData,
    transformWebSocketData,
    transformResponseDataAstarte,
    transformWebSocketDataAstarte
}