import {loadAstarteToken} from "../../localStorage";
import axios from "axios";

async function new_status_astarte (post: string, visibility: string) {
    const loadAccessToken = await loadAstarteToken()
    if (loadAccessToken === 'no data') {
        return false
    }
    let range = "public"
    if (visibility === "follower") {
        range = "private"
    }
    try {
        const response = await axios.post('https://kirishima.cloud/api/v1/statuses', {
            status: post,
            visibility: range,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': loadAccessToken
            }
        });
        if (response.status === 200 ) {
            return true
        }

    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                return false
            }
        } else {
            return false
        }
    }
    return false
}

export {
    new_status_astarte
}