import {AppBar, Box, Grid, Hidden, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {useRecoilState} from "recoil";
import {openDrawerAtom} from "../../Atoms";
import {Route, Routes, useNavigate} from "react-router-dom";
import Setting_list from "../setting/setting_list";
import Gacha from "./gacha/gacha";
import {GamesList} from "./games_list";

const GamesMenu: React.FC = () => {

  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useRecoilState(openDrawerAtom)

  return (
    <>
      <AppBar position="static" sx={{
        width: '100%'
      }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon

            />
          </IconButton>
          <Typography variant="h6" component="div" sx={{
            flexGrow: 1,
            overflow: 'hidden'
          }} noWrap>
            ゲーム
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid wrap="nowrap" container sx={{ height: 'calc(100vh - 115px)' }}>

        <Hidden mdDown implementation="css">
          <Box sx={{ flexGrow: 1, marginX: 0.25, minWidth: '250px', maxWidth: '250px', whiteSpace: 'normal' }}>
            <div className="w_setting_menu">
              <h4>ワイド用の設定メニュー</h4>
              <div onClick={()=>{
                navigate('/games/gacha', { replace: true });
              }}>
                <h5>ガチャを引く</h5>
              </div>
            </div>
          </Box>
        </Hidden>

        <Box sx={{ flexGrow: 3, marginX: 0.25, minWidth: '300px', whiteSpace: 'normal', overflowY: 'auto'}}>
          <Routes>
            <Route
              path=""
              element={
                <>
                  <h2>メニューから設定したい項目を選んでください</h2>
                  {/*モバイル用の選択メニュー*/}
                  <Hidden mdUp implementation="css">
                    <GamesList />
                  </Hidden>
                </>

              }
            />
            <Route
              path='/gacha'
              element={
                <Gacha />
              }
            />
          </Routes>
        </Box>
      </Grid>
    </>
  )
}

export default GamesMenu;