import {atom, selector} from "recoil";
import HomeIcon from "@mui/icons-material/Home";
// import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import {columnsType, shapedStatus} from "./types";
import {loadNeoAstarteToken} from "./invoke/localStorage";
import {dark} from "@mui/material/styles/createPalette";

const openDrawerAtom = atom<boolean>({
  key: "openDrawer",
  default: false
})

const columnsAtom = atom<columnsType[]>({
  key: "columns",
  default: [
    // { id: 0, name: 'ホーム', path: '/', icon: <HomeIcon />, component: <HomeIcon />},
    {id: 0, name: '公開', path: '/public', icon: <PublicIcon/>},
    {id: 1, name: 'ホームAs', path: '/astarte/home', icon: <HomeIcon/>},
    // {id: 2, name: '通知', path: '/', icon: <NotificationsIcon/>},
    {id: 2, name: '投稿', path: '/new_post', icon: <EditIcon/>},
    // { id: 1, name: '検索', path: '/home', icon: <SearchIcon />, component: <SearchIcon />},
  ]
})

const loadFromLocalStorage = (key: string, defaultValue: string) => {
  try {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      const data: string = "Bearer " + savedValue
      return data;
    }
  } catch (error) {
    console.error(`Error loading data from localStorage key "${key}":`, error);
  }
  return defaultValue;
};

const loadSettingFromLocalStorage = (key: string, defaultValue: string) => {
  try {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      return savedValue;
    } else {
      return defaultValue
    }
  } catch (error) {
    console.error(`Error loading data from localStorage key "${key}":`, error);
  }
  return defaultValue;
};

const accsessTokeAtom = atom<string>({
  key: "accessToken",
  default: loadFromLocalStorage('neo_astarte_token', "")
})

const accsessTokeAstarteAtom = atom<string>({
  key: "accessTokenAstarte",
  default: loadFromLocalStorage("astarte_token", "")
})

const postDataAtom = atom<string>({
  key: "post",
  default: ""
})

const post2sns = atom<string>({
  key: "post2sns",
  default: "neo"
})


const timelineItemSetting = atom<string>({
  key: "timelineItemSetting",
  default: loadSettingFromLocalStorage("timeline_item_view", "card")
})

const publicTimelineFetchStatus = atom<boolean>({
  key: "publicTimelineFetchStatus",
  default: false
})

const publicTimelineDataNeoAstarte = atom<shapedStatus[]>({
  key: "publicTimelineDataNeoAstarte",
  default: []
})

const publicTimelineWebSocketStatusNeoAstarte = atom<boolean>({
  key: "publicTimelineWebSocketStatusNeoAstarte",
  default: false
})

const publicTimelineOldestIdNeoAstarte = selector<string>({
  key: "publicTimelineOldestIdNeoAstarte",
  get: ({get}) => {
    const data = get(publicTimelineDataNeoAstarte)
    if (data.length > 0) {
      return data[data.length - 1].post_id
    } else {
      return ""
    }
  }
})

const publicTimelineNewestIdNeoAstarte = selector<string>({
  key: "publicTimelineNewestIdNeoAstarte",
  get: ({get}) => {
    const data = get(publicTimelineDataNeoAstarte)
    if (data.length > 0) {
      return data[0].post_id
    } else {
      return ""
    }
  }
})

const homeTimelineDataAstarte = atom<shapedStatus[]>({
  key: "homeTimelineDataAstarte",
  default: []
})

const homeTimelineFetchStatus = atom<boolean>({
  key: "homeTimelineFetchStatus",
  default: false
})

const homeTimelineWebSocketAstarte = atom<boolean>({
  key: "homeTimelineWebSocketAstarte",
  default: false
})

const homeTimelineOldestIdAstarte = selector<string>({
  key: "homeTimelineOldestIdAstarte",
  get: ({get}) => {
    const data = get(homeTimelineDataAstarte)
    if (data.length > 0){
      return data[data.length - 1].post_id
    } else {
      return ""
    }
  }
})

// const homeTimelineNewestIdAstarte = atom<string>({
//   key: "homeTimelineNewestIdAstarte",
//   default: ""
// })

const homeTimelineNewestIdAstarte = selector<string>({
  key: "homeTimelineNewestIdAstarte",
  get: ({get}) => {
    const data = get(homeTimelineDataAstarte)
    if (data.length > 0) {
      return data[0].post_id
    } else {
      return ""
    }
  }
})

const postLocalOnly2Astarte = atom<string>({
  key: "postLocalOnly2Astarte",
  default: loadSettingFromLocalStorage("post_Local_only_to_astarte", "local")
})

export {
  openDrawerAtom,
  columnsAtom,
  accsessTokeAtom,
  post2sns,
  postDataAtom,
  timelineItemSetting,
  publicTimelineFetchStatus,
  publicTimelineDataNeoAstarte,
  publicTimelineWebSocketStatusNeoAstarte,
  publicTimelineOldestIdNeoAstarte,
  publicTimelineNewestIdNeoAstarte,

  //------------------------------------------------------

  accsessTokeAstarteAtom,
  homeTimelineDataAstarte,
  homeTimelineFetchStatus,
  homeTimelineWebSocketAstarte,
  homeTimelineOldestIdAstarte,
  homeTimelineNewestIdAstarte,
  postLocalOnly2Astarte
}