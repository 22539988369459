import "./setting.css"
import CreateNeoAstarteAccount from "./setting_item/create_neoastarte_account";
import {Route, Routes, useNavigate} from "react-router-dom";
import VerifyEmail from "./setting_item/verify_email";
import {AppBar, Box, Grid, Hidden, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {useRecoilState} from "recoil";
import {openDrawerAtom} from "../../Atoms";
import Setting_list from "./setting_list";
import Neoastarte_login from "./setting_item/neoastarte_login";
import Astarte_login from "./setting_item/astarte_login";
import TimeLineItemView from "./setting_item/timeline_item_view";

export function Setting() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useRecoilState(openDrawerAtom)
  // const match = useMatch('settings/*');
  return (
    <>
      <AppBar position="static" sx={{
        width: '100%'
      }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{
            flexGrow: 1,
            overflow: 'hidden'
          }} noWrap>
            設定
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid wrap="nowrap" container sx={{height: 'calc(100vh - 115px)'}}>

        {/*ワイドビュー用のサイドバー*/}
        <Hidden mdDown implementation="css">
          <Box sx={{flexGrow: 1, marginX: 0.25, minWidth: '250px', maxWidth: '250px', whiteSpace: 'normal'}}>
            <div className="w_setting_menu">
              <h4>ワイド用の設定メニュー</h4>
              <div onClick={() => {
                navigate('/setting/create_neoastarte_account', {replace: true});
              }}>
                <h5>ネオアスタルテのアカウント作成</h5>
              </div>
              <div onClick={() => {
                navigate('/setting/verify_email', {replace: true});
              }}>
                <h5>ネオアスタルテのメール認証</h5>
              </div>
              <div onClick={() => {
                navigate('/setting/neoastarte_login', {replace: true});
              }}>
                <h5>ネオアスタルテにログイン</h5>
              </div>
              <div onClick={() => {
                navigate('/setting/astarte_login', {replace: true});
              }}>
                <h5>アスタルテにログイン</h5>
              </div>
              <div onClick={() => {
                navigate('/setting/timeline_item_view', {replace: true});
              }}>
                <h5>タイムラインの表示に関する設定</h5>
              </div>
            </div>
          </Box>
        </Hidden>

        <Box sx={{flexGrow: 3, marginX: 0.25, minWidth: '300px', whiteSpace: 'normal', overflowY: 'auto'}}>
          <Routes>
            <Route
              path=""
              element={
                <>
                  <h2>メニューから設定したい項目を選んでください</h2>

                  {/*モバイル用の選択メニュー*/}
                  <Hidden mdUp implementation="css">
                    <Setting_list/>
                  </Hidden>
                  <br/>
                  <br/>
                  <br/>
                  <h2>atelier7が要求する権限について</h2>
                  <p>atelier7は以下の権限をアプリケーションの正常な動作のために必要とします。</p>
                  <h4>ネットワークにアクセスする権限</h4>
                  <p>各種SNSにおけるサーバーにアクセスを行い、情報の取得、投稿等を行うために必要とします。</p>
                  <h4>セキュアストレージにアクセスする権限</h4>
                  <p>各種SNSにおけるアクセストークンの取得後、安全に保存するためにセキュアストレージを利用します。</p>
                  <h4>ストレージにアクセスする権限(現在未実装)</h4>
                  <p>各種SNSに写真、動画等をアップロードする際に、ストレージへのアクセスが必要となるため。</p>
                  <h4>バージョン</h4>
                  <p>Atelier7(web版) ver 1.0.15</p>
                </>

              }
            />
            <Route
              path='/create_neoastarte_account'
              element={
                <CreateNeoAstarteAccount/>
              }
            />
            <Route
              path='/verify_email'
              element={
                <VerifyEmail/>
              }
            />
            <Route
              path='/neoastarte_login'
              element={
                <Neoastarte_login/>
              }
            />
            <Route
              path='/astarte_login'
              element={
                <Astarte_login/>
              }
            />
            <Route
              path='/timeline_item_view'
              element={
                <TimeLineItemView/>
              }
            />
          </Routes>

        </Box>
      </Grid>
    </>

    // <div className="setting">
    //   <div className="w_setting_menu">
    //     <h4>ワイド用の設定メニュー</h4>
    //     <div onClick={()=>{
    //       navigate('/setting/create_neoastarte_account', { replace: true });
    //     }}>
    //       <h5>ネオアスタルテのアカウント作成</h5>
    //     </div>
    //     <div onClick={()=>{
    //       navigate('/setting/verify_email', { replace: true });
    //     }}>
    //       <h5>ネオアスタルテのメール認証</h5>
    //     </div>
    //   </div>
    //   <div className="m_setting_menu">モバイル用の設定メニュー</div>
    //   <div className="setting_main">
    //     <h1>設定画面</h1>
    //     <Routes>
    //       <Route
    //         path=""
    //         element={
    //           <p>メニューから設定したい項目を選んでください</p>
    //         }
    //       />
    //       <Route
    //         path='/create_neoastarte_account'
    //         element={
    //           <CreateNeoAstarteAccount/>
    //         }
    //       />
    //       <Route
    //         path='/verify_email'
    //         element={
    //           <VerifyEmail/>
    //         }
    //       />
    //     </Routes>
    //   </div>
    // </div>
  )
}