function writeStringData(key: string, data: string) {
    localStorage.setItem(key, data)
}

async function loadNeoAstarteToken() {
    const token = localStorage.getItem('neo_astarte_token')
    if (token) {
        const data: string = "Bearer " + token
        return data
    } else {
        return 'no data'
    }
}

async function loadAstarteToken() {
    const token = localStorage.getItem('astarte_token')
    if (token) {
        const data: string = "Bearer " + token
        return data
    } else {
        return 'no data'
    }
}

export {
    writeStringData,
    loadNeoAstarteToken,
    loadAstarteToken
}