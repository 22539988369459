import {useState} from "react";
import './create_neoastarte_account.css'
import axios from "axios";
import {defaultErrorMessage} from "../../../types";
import {Button, TextField} from "@mui/material";

export default function CreateNeoAstarteAccount() {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('');
  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState('')
  const [password, setPassword] = useState('')
  const [rePassword, setRePassWord] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [rePasswordError, setRePasswordError] = useState('')
  const [log, setLog] = useState('')

  async function handleSubmit() {
    try {
      const response = await axios.post('https://neo.krsm.io/api/user/create_account', {
        username: username,
        password: password,
        email: email
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.status === 200 ) {
        setLog("アカウントが無事制作されました。入力したメールアドレスに認証用の情報が届いています。")
      }

    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400) {
          const errorData: defaultErrorMessage = error.response.data;
          // ここで errorData を使用
          setLog(errorData.message)
        }
      } else {
        setLog("サーバーとの通信に失敗しました。ネット環境を確認するか、しばらく経ってからお試しください。")
      }
    }
  }

  const handleEmailBlur = () => {
    if (!validateEmail(email)) {
      setEmailError('有効なメールアドレスを入力してください');
    } else {
      setEmailError('');
    }
  };

  const validateEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleUsernameBlur = () => {
    if (username === "") {
      setUsernameError("取得ユーザーネームを入力してください")
    } else {
      setUsernameError("")
    }
  }

  const handlePasswordBlur = () => {
    if (password === "") {
      setPasswordError("パスワードを入力してください")
    }
    if (password.length <= 8) {
      setPasswordError("パスワードは8文字以上が必須です")
    }

    if (password !== "" && password.length >= 9) {
      setPasswordError("")
    }
  }

  const handleRePasswordBlur = () => {
    if (password !== rePassword) {
      setRePasswordError("パスワードが一致しません")
    } else {
      setRePasswordError("")
    }
  }

  return (
    <div className='CreateNeoAstarteAccount'>
      <h3 style={{ textAlign: "center"}}>ネオアスタルテのアカウント作成</h3>
      <form onSubmit={handleSubmit}  style={{ display: 'flex', flexDirection: 'column', width: '300px', margin: 'auto' }}>
        <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={handleEmailBlur}
            error={emailError !== ''}
            helperText={emailError}
        />
        {
            username === '' &&
            <div>
              <p>アルファベット、数字、日本語(漢字を含む)が利用できます</p>
              <p>設定したユーザーネームは変更できません。</p>
              <p>表示名は別途設定できます</p>
            </div>
            ||
            <br/>
        }
        <TextField
            label="username"
            variant="outlined"
            margin="normal"
            value={username}
            onBlur={handleUsernameBlur}
            error={usernameError !== ''}
            helperText={usernameError}
            onChange={(e) => setUsername(e.target.value)}
        />
        {
          password === "" &&
            <div>
              <p>
                パスワードは9文字以上が必須です。
              </p>
            </div>
        }
        <TextField
            label="Password"
            variant="outlined"
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={handlePasswordBlur}
            error={passwordError !== ''}
            helperText={passwordError}
        />
        <TextField
            label="Password"
            variant="outlined"
            margin="normal"
            type="password"
            value={rePassword}
            onChange={(e) => setRePassWord(e.target.value)}
            onBlur={handleRePasswordBlur}
            error={rePasswordError !== ''}
            helperText={rePasswordError}
        />
        {email !== '' && username !== '' && password === rePassword && password.length >= 8 &&
            <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
              アカウント登録
            </Button>
        }
      </form>
      <p>
        {log}
      </p>
    </div>
  )

}