import {clientResultAstarte, getTokenResponse, keyringDataType, loginResult} from "../../../types";
import {useState} from "react";
import {Button, TextField} from "@mui/material";
import axios from "axios";
import {writeStringData} from "../../../invoke/localStorage";

type application = {
    id: string,
    name: string,
    website: string,
    redirect_uri: string,
    client_id: string,
    client_secret: string,
    vapid_key: string
}

type token  = {
    access_token: string,
    token_type: string,
    scope: string,
    created_at: number
}

const Astarte_login = () => {

  const [clientId, setClietId] = useState("");
  const [clientSecret, setClietSecret] = useState("");
  const [view, setView] = useState("create_app")
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState("コードの入力は必須です")

  const handleLoginStart = async () => {
      try {
          const response = await axios.post('https://kirishima.cloud/api/v1/apps', {
              client_name: "アトリエ",
              redirect_uris: "urn:ietf:wg:oauth:2.0:oob",
              scopes: "read write follow",
          }, {
              headers: {
                  'Content-Type': 'application/json',
              }
          });
          if (response.status === 200 ) {
              const application_data : application = response.data
              setClietId(application_data.client_id)
              setClietSecret(application_data.client_secret)
              setView("get_code")
          }

      } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
              if (error.response.status === 400) {
                  setView("")
              }
          } else {
              setView("")
          }
      }
    // let data　= await  invoke<loginResult>(
    //   "create_client_astarte"
    // )
    //
    // if (data.result) {
    //   //@ts-ignore
    //   const result: clientResultAstarte = data.login_data
    //   setClietId(result.client_id)
    //   setClietSecret(result.client_secret)
    //   setView("get_code")
    // }　else {
    //   setView("")
    // }
  }

  const handleGetCodeStart = async () => {
    const url = "https://kirishima.cloud/oauth/authorize?";
    const params = {
      "response_type": "code",
      "client_id": clientId,
      "redirect_uri": "urn:ietf:wg:oauth:2.0:oob",
      "scope": "read write follow",
    }
    const query = new URLSearchParams(params)
    window.open(`${url}${query}`, '_blank');
    setView("oauth")
  }

  const handleSubmit = async () => {
      try {
          const response = await axios.post('https://kirishima.cloud/oauth/token', {
              client_id: clientId,
              client_secret: clientSecret,
              redirect_uri: "urn:ietf:wg:oauth:2.0:oob",
              scopes: "read write follow",
              code: code,
              grant_type: "authorization_code",
          }, {
              headers: {
                  'Content-Type': 'application/json',
              }
          });
          if (response.status === 200 ) {
              const token_data: token = response.data
              writeStringData("astarte_token", token_data.access_token)
              setView("done")
          }

      } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
              if (error.response.status === 400) {
                  setView("")
              }
          } else {
              setView("")
          }
      }
    // let data　= await  invoke<loginResult>(
    //   "get_token_astarte",
    //   {
    //     payload: {
    //       client_id: clientId,
    //       client_secret: clientSecret,
    //       code: code
    //     }
    //   }
    // )
    //
    // if (data.result) {
    //   //@ts-ignore
    //   const result: getTokenResponse = data.login_data
    //   setView("done")
    //   await invoke<keyringDataType>('write_data',
    //     {payload : {
    //         'key': "access_token_astarte",
    //         //@ts-ignore
    //         'data': result.access_token
    //       }}
    //   )
    //   console.log(result.access_token)
    // } else {
    //   setView("")
    // }
  }

  const handleCodeBlur = () => {
    if (code === "") {
      setCodeError('認証コードを入力してください');
    } else {
      setCodeError('');
    }
  };

  const Component = () => {
    if (view === "create_app") {
      return (
        <>
          <button　onClick={()=>{
            handleLoginStart()
          }}>
            ログインを開始する
          </button>
        </>
      )
    } else if (view === "get_code") {
      return (
        <>
            <p>ポップアップをブロックする設定を解除する必要があります。</p>
          <button　onClick={()=>{
            handleGetCodeStart()
          }}>
            ログインコードを入手する
          </button>
        </>
      )
    } else if (view === "oauth") {
      return (
        <>
          <form style={{ display: 'flex', flexDirection: 'column', width: '300px', margin: 'auto' }}>
            <TextField
              label="code"
              variant="outlined"
              margin="normal"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              onBlur={handleCodeBlur}
              error={codeError !== ""}
              helperText={codeError}
            />
            <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={()=>{
              handleSubmit()
            }}>
              ログイン
            </Button>
          </form>
        </>
      )
    } else if (view === "done") {
      return (
        <>
          <p>ログインが完了しました</p>
          <p>アスタルテをお楽しみいただけます</p>
        </>
      )
    } else {
      return (
        <>
          <p>問題が発生しました。しばらくしてからやり直してください。</p>
        </>
      )
    }
  }

  return (
    <>
      <p>アスタルテアカウントにログインする。</p>
      <p>また安全性の観点からこのページを離れるとリセットされるようになっているため、ログインを開始したらこのページを離れないようにしてください。</p>
      <Component />

    </>
  )
}

export default Astarte_login