import React, {useState} from "react";
import gacha_stop from "../../../assets/6028.png";
import gacha_start from "../../../assets/5983.gif"
import {defaultErrorMessage, gachaDataType, gachaResultType,} from "../../../types";
import {useRecoilValue} from "recoil";
import {accsessTokeAtom} from "../../../Atoms";
import {Button, Card, CardContent, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {loadNeoAstarteToken} from "../../../invoke/localStorage";
import axios from "axios";
import {transformResponseData} from "../../../invoke/neo/statuses/transformPostData";
import post_new_status from "../../../invoke/neo/statuses/new_status";

type Props = {
  start: string
}

type GachaResult = {
  result: string,
  data: gachaDataType | defaultErrorMessage
}

const Gacha: React.FC = () => {

  const [start, setStart] = useState("stop");
  const accessToken = useRecoilValue(accsessTokeAtom)
  const [gachaResult, setGachaResult] = useState<gachaResultType[]|null>(null)
  const [resultText, setResultText] = useState("");
  const navigate = useNavigate();

  const handleGacha = async () => {
    const data = await loadNeoAstarteToken()
    setStart("start")
    try {
      const response = await axios.get('https://neo.krsm.io/api/games/gacha', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': data
        },
      });
      if (response.status === 200 ) {
        const successData: gachaDataType = response.data
        setGachaResult(successData.result)
        let result_text = `今日のアスタルテガチャの結果は\n`
        for (const v of successData.result) {
          result_text += `${v.rarity}: ${v.content}\n`
        }
        result_text += `でした。`
        setResultText(result_text)
        setTimeout(() => {
          setStart("end")
        }, 5000);
      }

    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 405) {
          setTimeout(() => {
            setStart("limit")
          }, 5000);
        }
      } else {

      }
    }

    // const data = await invoke<GachaResult>('gacha',
    //   {
    //     payload: {
    //       'access_token': accessToken
    //     }
    //   }
    // )
    // setStart("start")
    // if (data.result === "success") {
    //   // @ts-ignore
    //   let success_data: gachaDataType = data.data
    //   setGachaResult(success_data.result)
    //   let result_text = `今日のアスタルテガチャの結果は\n`
    //   for (const v of success_data.result) {
    //     result_text += `${v.rarity}: ${v.content}\n`
    //   }
    //   result_text += `でした。`
    //   setResultText(result_text)
    //   setTimeout(() => {
    //     setStart("end")
    //   }, 5000);
    // } else if (data.result == "error") {
    //   // @ts-ignore
    //   let error_data: defaultErrorMessage = data.data
    //   if (error_data.message === "ガチャは一日一回までだよ！")
    //   setTimeout(() => {
    //     setStart("limit")
    //   }, 5000);
    // }

  }

  const postButton = async () => {
    await post_new_status(resultText)
    navigate('/', { replace: true });
  }

  const GachaResult = (props: gachaResultType) => {

    const [showReal, setShowReal] = useState(true);
    const getRarityColor = () => {
      switch (props.rarity) {
        case 'LR':
          return 'purple';
        case 'UR':
          return 'red';
        case 'SSR':
          return 'orange';
        case 'SR':
          return 'yellow';
        case 'R':
          return 'green';
        case 'HN':
          return 'blue';
        case 'N':
          return 'gray';
        default:
          return 'black';
      }
    };

    return (
      <Card sx={{ mb: 2, border: `2px solid ${getRarityColor()}` }}>
        <CardContent>
          <Typography variant="subtitle1" color={getRarityColor()}>
            Rarity: {props.rarity}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {showReal ? props.content : props.fake || props.content}
          </Typography>
          {props.fake && (
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => setShowReal(!showReal)}>
              ...
            </Button>
          )}
        </CardContent>
      </Card>
    );
  };

  const GachaImage: React.FC<Props> = (props) => {
    if (props.start === "stop") {
      return (
        <div style={{width: '370px', height: '320px',}}>
          <img src={gacha_stop} />
          <p></p>
          <button onClick={()=>{
            handleGacha()
          }}>ガチャをまわす</button>
        </div>
      )
    } else if (props.start === "start") {
      return (
        <div style={{width: '370px', height: '320px',}}>
          <img src={gacha_start} />
        </div>
      )
    } else if (props.start === "end") {
      return (
        <>終わり</>
      )
    } else if (props.start === "limit") {
      return (
        <>ガチャは一日一回までだよ！</>
      )
    } else  {
      return (
        <>ロードちゅー</>
      )
    }
  }

  if (start === "end" && gachaResult != null) {
    return (
      <div style={{textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <h1>結果一覧</h1>
        <div>
          {gachaResult.map((result, index) => (
            <GachaResult key={index} {...result} />
          ))}
        </div>
        <button onClick={()=>{
          postButton()
        }}>結果を投稿する</button>
      </div>
    )
  }

  return (
    <div style={{textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
      <h1>ガチャをまわす</h1>
      <GachaImage
        start = {start}
      />
    </div>
  )
}



export default Gacha