import {useRecoilState, useRecoilValue} from "recoil";
import {
  accsessTokeAstarteAtom,
  homeTimelineDataAstarte,
  homeTimelineFetchStatus,
  homeTimelineNewestIdAstarte,
  homeTimelineOldestIdAstarte,
  homeTimelineWebSocketAstarte,
  openDrawerAtom, timelineItemSetting
} from "../../../Atoms";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AppBar, Box, Hidden, IconButton, List, ListItem, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import InfiniteScroll from "react-infinite-scroll-component";
import TimelineItem from "../../neo/timeline/timeline_item";
import {loadAstarteToken} from "../../../invoke/localStorage";
import axios from "axios";
import {transformResponseDataAstarte} from "../../../invoke/neo/statuses/transformPostData";
import {favouriteAstarte, unfavouriteAstarte} from "../../../invoke/astarte/statuses/favourite";
import TimeLineItemCompress from "../../neo/timeline/timeline__item__compress";
import {reblogAstarte} from "../../../invoke/astarte/statuses/reblog";

type Props = {
  reconnectAH: () => void
}

const HomeAstarte: React.FC<Props> = ({reconnectAH}) => {

  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useRecoilState(openDrawerAtom)
  const [accessToken, setAccessToken] = useRecoilState(accsessTokeAstarteAtom);
  const [items, setItems] = useRecoilState(homeTimelineDataAstarte);
  const [fetch, setFetch] = useRecoilState(homeTimelineFetchStatus);
  const newestId = useRecoilValue(homeTimelineNewestIdAstarte);
  const oldestId = useRecoilValue(homeTimelineOldestIdAstarte);
  const wsAH = useRecoilValue(homeTimelineWebSocketAstarte)
  const timelineItemView = useRecoilValue(timelineItemSetting)

  useEffect(() => {
    const loadData = async () => {
      setItems([])
      if (accessToken === "") {
        const loadAccessToken = await loadAstarteToken()
        if (loadAccessToken !== 'no data') {
          // @ts-ignore
          setAccessToken(loadAccessToken)
        }
      }
      const data = await loadAstarteToken()
      setAccessToken(data)

      if (!fetch) {
        setFetch(true)
        try {
          const response = await axios.get('https://kirishima.cloud/api/v1/timelines/home', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': data
            }
          });
          if (response.status === 200) {
            const successData = transformResponseDataAstarte(response.data)
            setItems(successData)
          }

        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 405 || error.response.status === 400 || error.response.status === 401) {
            } else {
            }
          } else {
          }
        }
        setFetch(false)
      }
    }
    if (items.length <= 3) {
      loadData()
    }
  }, [])

  function parseLinkHeader(linkHeader: string): { max_id?: string, min_id?: string } {
    const parsedValues = {max_id: undefined, min_id: undefined};
    const regex = /<https:\/\/kirishima\.cloud\/api\/v1\/timelines\/home\?(.+?)>; rel="(next|prev)"/g;
    let match;

    while ((match = regex.exec(linkHeader)) !== null) {
      const params = new URLSearchParams(match[1]);
      if (match[2] === 'next' && params.has('max_id')) {
        // @ts-ignore
        parsedValues.max_id = params.get('max_id');
      } else if (match[2] === 'prev' && params.has('min_id')) {
        // @ts-ignore
        parsedValues.min_id = params.get('min_id');
      }
    }

    return parsedValues;
  }

  // 新しいデータを取得する関数
  const fetchData = async () => {

    const data = await loadAstarteToken()
    // WebSocket接続中は引っ張って更新をさせない
    if (!wsAH) {
      if (!fetch) {
        setFetch(true)
        try {
          const response = await axios.get('https://kirishima.cloud/api/v1/timelines/home', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': data
            },
            params: {
              'min_id': newestId
            }
          });
          if (response.status === 200) {
            const successData = transformResponseDataAstarte(response.data)
            // @ts-ignore
            setItems(items => [...successData, ...items])
          }

        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 405 || error.response.status === 400 || error.response.status === 401) {

            }
          } else {

          }
        }
        setFetch(false)
      }
    }
  };

  const loadMore = async () => {

    const data = await loadAstarteToken()

    if (!fetch) {
      setFetch(true)
      try {
        const response = await axios.get('https://kirishima.cloud/api/v1/timelines/home', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': data
          },
          params: {
            'max_id': oldestId
          }
        });
        if (response.status === 200) {
          const successData = transformResponseDataAstarte(response.data)
          // @ts-ignore
          setItems(items => [...items, ...successData])
        }

      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          if (error.response.status === 405 || error.response.status === 400 || error.response.status === 401) {

          }
        } else {

        }
      }
      setFetch(false)
    }
  }

  const favourite = async (type: string, post_id: string, isFavourite: boolean) => {
    if (type === "mastodon") {
      if (isFavourite) {
        // すでにお気に入り登録されているならアンファボする
        const result = await unfavouriteAstarte(post_id)
        if (result) {
          const newItems = items.map(item => {
            if (item.post_id === post_id) {
              return {...item, favourited: false}
            }
            return item
          })
          setItems(newItems)
        }
      } else {
        // まだお気に入り登録されていないのでファボる
        const result = await favouriteAstarte(post_id)
        if (result) {
          const newItems = items.map(item => {
            if (item.post_id === post_id) {
              return {...item, favourited: true}
            }
            return item
          })
          setItems(newItems)
        }
      }
    }
  }

  const reblog = async (type: string, post_id: string, isFavourite: boolean) => {
    if (type === "mastodon") {
      if (isFavourite) {
        // すでにリブログされているならリブログを解除する
        const result = await unfavouriteAstarte(post_id)
        if (result) {
          const newItems = items.map(item => {
            if (item.post_id === post_id) {
              return {...item, reblog: false}
            }
            return item
          })
          setItems(newItems)
        }
      } else {
        // まだリブログされていないのでリブログする
        const result = await reblogAstarte(post_id)
        if (result) {
          const newItems = items.map(item => {
            if (item.post_id === post_id) {
              return {...item, reblog: true}
            }
            return item
          })
          setItems(newItems)
        }
      }
    }
  }

  const ReconnectButton = () => {
    const handleClick = () => {
      if (!wsAH) {
        console.log("接続を再開します")
        reconnectAH()
      } else {
        console.log("接続されています")
      }
    }
    switch (wsAH) {
      case true:
        return (
          <IconButton
            size="large"
            edge="start"
            color="default"
            aria-label="menu"
            sx={{mr: 2}}
          >
            <WifiIcon/>
          </IconButton>
        )
      case false:
        return (
          <IconButton
            size="large"
            edge="start"
            color="error"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={handleClick}
          >
            <WifiOffIcon/>
          </IconButton>
        )
    }
  }

  return (
    <>
      {/*<Hidden mdUp implementation="css">*/}
      <AppBar position="static" sx={{
        width: '100%'
      }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{
            flexGrow: 1,
            overflow: 'hidden'
          }} noWrap>
            ホームタイムライン(As)
          </Typography>
          <ReconnectButton/>
        </Toolbar>
      </AppBar>
      {/*</Hidden>*/}

      {/*モバイルのデザイン*/}

      <Hidden mdUp implementation="css">
        <Box id="scrollableListMobileAstarte"
             style={{padding: '5px', height: 'calc(100vh - 132px - 85px)', margin: 'auto', overflowY: 'scroll'}}>
          <InfiniteScroll
            dataLength={items.length}
            next={loadMore}
            hasMore={true}
            scrollableTarget="scrollableListMobileAstarte"
            loader={<h4>Loading...</h4>}
            pullDownToRefresh={true}
            pullDownToRefreshContent={
              <div>/ｼｭｨｨｨｲｲｲｲｲｲｲｲﾝ\</div>
            }
            releaseToRefreshContent={
              <div>\ｼｭﾎﾟ/</div>
            }
            refreshFunction={fetchData}
            pullDownToRefreshThreshold={100}
            scrollThreshold={0.95}
          >
            <List>
              {items.map((item, index) => (
                <ListItem key={index}>
                  {timelineItemView === "card" ?
                    <TimelineItem
                      status={item}
                      favourite={favourite}
                      reblog={reblog}
                    />
                    :
                    <TimeLineItemCompress
                      status={item}
                      favourite={favourite}
                      reblog={reblog}
                    />
                  }
                </ListItem>
              ))}
            </List>
          </InfiniteScroll>
        </Box>
      </Hidden>

      {/*PCのデザイン*/}

      <Hidden mdDown implementation="css">
        <Box id="scrollableListWideAstarte"
             style={{padding: '5px', height: 'calc(100vh - 84px)', margin: 'auto', overflowY: 'scroll'}}>
          <InfiniteScroll
            dataLength={items.length}
            next={loadMore}
            hasMore={true}
            scrollableTarget="scrollableListWideAstarte"
            loader={<h4>Loading...</h4>}
            pullDownToRefresh={true}
            pullDownToRefreshContent={
              <div>/ｼｭｨｨｨｲｲｲｲｲｲｲｲﾝ\</div>
            }
            releaseToRefreshContent={
              <div>\ｼｭﾎﾟ/</div>
            }
            refreshFunction={fetchData}
            pullDownToRefreshThreshold={100}
            scrollThreshold={0.95}
          >
            <List>
              {items.map((item, index) => (
                <ListItem key={index}>
                  {timelineItemView === "card" ?
                    <TimelineItem
                      status={item}
                      favourite={favourite}
                      reblog={reblog}
                    />
                    :
                    <TimeLineItemCompress
                      status={item}
                      favourite={favourite}
                      reblog={reblog}
                    />
                  }
                </ListItem>
              ))}
            </List>
          </InfiniteScroll>
        </Box>
      </Hidden>

    </>
  )
}

export default HomeAstarte;