import { useState, useEffect, useCallback } from 'react';
import {transformWebSocketData, transformWebSocketDataAstarte} from "./neo/statuses/transformPostData";
import {useSetRecoilState} from "recoil";
import {homeTimelineDataAstarte, publicTimelineDataNeoAstarte} from "../Atoms";

const useWebSocket = (url: string, sns: string, type: string) => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [messages, setMessages] = useState<string[]>([]);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const setAHItem = useSetRecoilState(homeTimelineDataAstarte)
    const setNPItem = useSetRecoilState(publicTimelineDataNeoAstarte)

    // イベントハンドラー
    // ここで受け取ったメッセージを処理する
    const eventHandler = (event: MessageEvent<any>) => {
        if (sns ==="astarte") {
            const data: any = JSON.parse(event.data);
            if (data.event === "update") {
                if (type === "home") {
                    const successData = transformWebSocketDataAstarte(JSON.parse(data.payload))
                    setAHItem(items => [successData, ...items])
                }
            }
        } else if (sns === "neo") {
            if (event.data instanceof Blob) {
                // event.data が Blob の場合
                const reader = new FileReader();
                reader.onload = () => {
                    try {
                        const text = reader.result as string;
                        const jsonData = JSON.parse(text);
                        if (jsonData.data_type === "StatusData") {
                            const data = transformWebSocketData(jsonData.data.StatusData)
                            setNPItem(items => [data, ...items])
                        }
                    } catch (error) {}
                };
                reader.onerror = (error) => {};
                reader.readAsText(event.data);
            }

        }
    }

    // 接続に関する部分をカプセル化して呼び出せるようにする。
    const connectWebSocket = useCallback(() => {
        const ws = new WebSocket(url);

        ws.onopen = () => {
            console.log(`接続しました${url}`)
            setIsConnected(true)
        };
        ws.onclose = () => setIsConnected(false);
        ws.onmessage = (event) => {
            eventHandler(event)
        };

        setSocket(ws);
    }, [url]);

    // 最初に呼ばれた時に接続できるようにする。
    useEffect(() => {
        connectWebSocket();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [connectWebSocket]);

    // 再接続
    const reconnect = useCallback(() => {
        if (!socket || socket.readyState === WebSocket.CLOSED) {
            connectWebSocket();
        }
    }, [connectWebSocket]);

    // メッセージの送信
    const sendMessage = useCallback((message: string) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(message);
        }
    }, [socket]);

    return { sendMessage, messages, isConnected, reconnect };
};

export default useWebSocket;
