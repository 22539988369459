import {AppBar, Box, Grid, Toolbar, Typography} from "@mui/material";
import React from "react";
import Post from "../../post/post";
import {useRecoilValue} from "recoil";
import {columnsAtom} from "../../../Atoms";
import Public from "../timeline/public/public";
import HomeAstarte from "../../astarte/timeline/home";
import {reconnectFanctions} from "../../../types";


const Deck: React.FC<reconnectFanctions> = ({reconnectAH, reconnectNP}) => {

  const columns =　useRecoilValue(columnsAtom)

    const item = (type: string) => {
      switch (type) {
          case '公開':
              return <Public
                  recconectNP={reconnectNP}
              />;
          case '投稿':
              return <Post />;
          case 'ホームAs':
              return <HomeAstarte reconnectAH={reconnectAH}/>
          default:
              return <Public
                  recconectNP={reconnectNP}
              />;
      }
    }

  return (
    <Grid container wrap="nowrap" spacing={0} sx={{ width: '100%', overflowX: 'scroll', overflowY: 'hidden'}}>
      <Box component="div" sx={{ flexGrow: 1, marginX: 0.25, minWidth: '300px', whiteSpace: 'normal' }}>
        <Post />
      </Box>
      {columns.map((column, index) => (
        <Box key={index} component="div" sx={{ flexGrow: 1, marginX: 0.25, minWidth: '400px', whiteSpace: 'normal' }}>
          {/*<AppBar position="static">*/}
          {/*  <Toolbar>*/}
          {/*    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>*/}
          {/*      {column.name}*/}
          {/*    </Typography>*/}
          {/*  </Toolbar>*/}
          {/*</AppBar>*/}
          {item(column.name)}
        </Box>
      ))}
    </Grid>
  )
}

export default Deck