import {
  AppBar,
  Box,
  Button,
  IconButton, MenuItem,
  Select, TextField,
  Toolbar,
  Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, {useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {accsessTokeAtom, openDrawerAtom, post2sns, postDataAtom, postLocalOnly2Astarte} from "../../Atoms";
import post_new_status from "../../invoke/neo/statuses/new_status";
import {new_status_astarte} from "../../invoke/astarte/statuses/new_status";
import {writeStringData} from "../../invoke/localStorage";


type neoAstartePostResult = {
  result: boolean
}

const Post: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useRecoilState(openDrawerAtom)
  const [postRange, setPostRange] = useState('public');
  const [sns, setSns] = useRecoilState(post2sns)
  const [waiting, setWaiting] = useState(false)
  const [local, setLocal] = useRecoilState(postLocalOnly2Astarte)
  const accessToken = useRecoilValue(accsessTokeAtom)
  const [post, setPost] = useRecoilState(postDataAtom);

  const handleChangePostRange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setPostRange(event.target.value);
  };

  const handleChangePost = (event: { target: { value: string | ((currVal: string) => string); }; }) => {
    setPost(event.target.value)
  }

  const handleChangeSNS = (event: { target: { value: string | ((currVal: string) => string); }; }) => {
    setSns(event.target.value)
  }

  const handleChangeLocal = (event: { target: { value: string | ((currVal: string) => string); }; }) => {
    setLocal(event.target.value)
    //@ts-ignore
    writeStringData("post_Local_only_to_astarte", event.target.value)
  }

  const postButton = async () => {

    if (sns === "neo") {
      // 空白投稿を許可しない
      if (post !== "" && !waiting) {
        setWaiting(true)
        const status = await post_new_status(post, postRange)
        if (status) {
          setPost("")
        }
        setWaiting(false)
      }
    } else if (sns === "mastodon") {
      // 空白投稿を許可しない
      if (post !== "" && !waiting) {
        setWaiting(true)
        let status
        if (local === "local") {
          status = await new_status_astarte(`${post} 👁️`, postRange)
        } else {
          status = await new_status_astarte(post, postRange)
        }
        if (status) {
          setPost("")
        }
        setWaiting(false)
      }
    }
  }

  function LocalOnlySelect() {
    if (sns === "mastodon") {
      return (
          <div style={{marginBottom: '10px'}}>
            <Select
                labelId="post-local-select-label"
                id="post-local-select"
                value={local}
                onChange={handleChangeLocal}
                fullWidth
            >
              <MenuItem value={"local"}>ローカル限定</MenuItem>
              <MenuItem value={"public"}>連合へ投稿</MenuItem>
            </Select>
          </div>
      )
    } else {
      return (
          <></>
      )
    }
  }

  // function MekamushiSupport　(){
  //   if (sns === "mastodon") {
  //     return (
  //       <div style={{marginBottom: '10px'}}>
  //         <Select
  //           labelId="post-mekamushi-command-label"
  //           id="post-mekamushi-command"
  //           value={local}
  //           onChange={handleChangeLocal}
  //           fullWidth
  //         >
  //           <MenuItem value={"local"}>1日1回ガチャ</MenuItem>
  //           <MenuItem value={"public"}>Aspointガチャ</MenuItem>
  //         </Select>
  //         <span>実行！</span>
  //       </div>
  //     )
  //   } else {
  //     return (
  //       <></>
  //     )
  //   }
  // }

  return (
    <>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <MenuIcon/>
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              投稿
            </Typography>
          </Toolbar>
        </AppBar>

        <Box style={{ padding: '5px', height: 'calc(100vh - 115px)', maxWidth: '500px', margin: 'auto', overflowY: 'scroll'}}>
          <div>
            <TextField
              label="素数数えてる？"
              multiline
              rows={8}
              variant="outlined"
              fullWidth
              value={post}
              margin="normal"
              onChange={handleChangePost}
            />
          </div>
          <div style={{marginBottom: '10px'}}>
            <Select
              labelId="post-range-select-label"
              id="post-range-select"
              value={postRange}
              onChange={handleChangePostRange}
              fullWidth
            >
              <MenuItem value={"public"}>公開</MenuItem>
              <MenuItem value={"follower"}>フォロワーのみ</MenuItem>
            </Select>
          </div>
          <div style={{marginBottom: '10px'}}>
            <Select
                labelId="post-sns-select-label"
                id="post-sns-select"
                value={sns}
                onChange={handleChangeSNS}
                fullWidth
            >
              <MenuItem value={"neo"}>ネオアスタルテ</MenuItem>
              <MenuItem value={"mastodon"}>アスタルテ</MenuItem>
            </Select>
          </div>
          {LocalOnlySelect()}
          {/*<MekamushiSupport/>*/}
          <Button variant="contained" color="primary" fullWidth onClick={() => {
            postButton()
          }}>
            投稿
          </Button>
        </Box>
    </>
  )
}

export default Post;