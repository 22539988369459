import {useState} from "react";
import {Collapse, List, ListItem, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const Setting_list = () => {

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      <ListItem button onClick={handleClick}>
        <ListItemText primary="設定メニュー" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button　onClick={()=>{
            navigate('/setting/create_neoastarte_account', { replace: true })
          }}>
            <ListItemText primary="ネオアスタルテのアカウント作成" />
          </ListItem>
          <ListItem button onClick={()=>{
            navigate('/setting/verify_email', { replace: true })
          }}>
            <ListItemText primary="ネオアスタルテのメール認証" />
          </ListItem>
          <ListItem button onClick={()=>{
            navigate('/setting/neoastarte_login', { replace: true })
          }}>
            <ListItemText primary="ネオアスタルテにログイン" />
          </ListItem>
          <ListItem button onClick={()=>{
            navigate('/setting/astarte_login', { replace: true })
          }}>
            <ListItemText primary="アスタルテにログイン" />
          </ListItem>
          <ListItem button onClick={()=>{
            navigate('/setting/timeline_item_view', { replace: true })
          }}>
            <ListItemText primary="タイムラインの表示に関する設定" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  )
}

export default Setting_list;