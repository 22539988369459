import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {useSetRecoilState} from "recoil";
import {accsessTokeAtom} from "../../../Atoms";
import {defaultErrorMessage, getTokenNeoAstarteResponse, keyringDataType} from "../../../types";
import axios from "axios";
import {writeStringData} from "../../../invoke/localStorage";

type SuccessResponse = {
  access_token: string,
  token_type: string
}

type LoginErrorResponse = {
  username: string,
  message: string
}

type SimpleResponse = {
  message: string
}

type LoginResult = SuccessResponse | LoginErrorResponse | SimpleResponse | string;

const Neoastarte_login = () => {

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [apiResponse, setApiResponse] = useState< null | string >(null);
  const setAccessToken = useSetRecoilState(accsessTokeAtom)
  //@ts-ignore
  const client_id: string = process.env.REACT_APP_CLIENT_ID;
  //@ts-ignore
  const client_secret: string = process.env.REACT_APP_CLIENT_SECRET;


  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const basicAuth = 'Basic ' + btoa(username + ':' + password);
    try {
      const response = await axios.post('https://neo.krsm.io/api/user/get_token', {
        grant_type: "password",
        email: email,
        client_id: client_id,
        client_secret: client_secret
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': basicAuth
        }
      });
      if (response.status === 200 ) {
        const successData: getTokenNeoAstarteResponse = response.data;
        writeStringData("neo_astarte_token", successData.access_token)
      }

    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 405 || error.response.status === 400 || error.response.status === 401 ) {
          const errorData: defaultErrorMessage = error.response.data;
          // ここで errorData を使用
          setApiResponse(errorData.message)
        }
      } else {

      }
    }
    // event.preventDefault();
    // const data: LoginResult = await invoke<string>('neoastarte_login',
    //   {
    //     payload: {
    //       'email': email,
    //       'username': username,
    //       'password': password
    //     }
    //   }
    // )
    // if (typeof data === 'string') {
    //
    // } else if ('access_token' in data) {
    //   await invoke<keyringDataType>('write_data',
    //     {payload : {
    //         'key': "access_token",
    //         //@ts-ignore
    //         'data': data.access_token
    //       }}
    //   )
    //   //@ts-ignore
    //   setAccessToken(data.access_token)
    //   setApiResponse("ログインが完了しました。")
    // } else if ('username' in data) {
    //   //@ts-ignore
    //   setApiResponse(data.message)
    // } else if ('message' in data) {
    //   //@ts-ignore
    //   setApiResponse(data.massage)
    // }

  };

  const handleEmailBlur = () => {
    if (!validateEmail(email)) {
      setEmailError('有効なメールアドレスを入力してください');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordBlur = () => {
    if (password.length < 8) {
      setPasswordError('パスワードは8文字以上です');
    } else {
      setPasswordError('');
    }
  };

  const validateEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <>
      <h3 style={{ textAlign: "center"}}>ネオアスタルテにログイン</h3>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '300px', margin: 'auto' }}>
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleEmailBlur}
          error={emailError !== ''}
          helperText={emailError}
        />
        <TextField
          label="username"
          variant="outlined"
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={handlePasswordBlur}
          error={passwordError !== ''}
          helperText={passwordError}
        />
        <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
          Login
        </Button>
      </form>
      {apiResponse && <div style={{ marginTop: '20px' , textAlign: 'center'}}>{apiResponse}</div>}
    </>

  )
}

export default Neoastarte_login;