import './verify_email.css'
import {useState} from "react";
import axios from "axios";
import {defaultErrorMessage} from "../../../types";
import {Button, TextField} from "@mui/material";
export default function VerifyEmail() {

  const [user_id, setUser_id] = useState('')
  const [code, setCode] = useState('')
  const [log, setLog] = useState('')

  async function handleSubmit() {
      try {
          const response = await axios.post('https://neo.krsm.io/api/user/email_verify', {
              user_id: user_id,
              code: code,
          }, {
              headers: {
                  'Content-Type': 'application/json',
              }
          });
          if (response.status === 200 ) {
              setLog("メールアドレス認証が完了しました")
          }

      } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
              if (error.response.status === 401 || error.response.status === 500 ) {
                  const errorData: defaultErrorMessage = error.response.data;
                  // ここで errorData を使用
                  setLog(errorData.message)
              }
          } else {
              setLog("サーバーとの通信に失敗しました。ネット環境を確認するか、しばらく経ってからお試しください。")
          }
      }
  }

  return (
    <div className="VerifyEmail">
      <h3 style={{ textAlign: "center"}}>ネオアスタルテのアカウント作成</h3>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '300px', margin: 'auto' }}>
          <TextField
              label="ユーザーID"
              variant="outlined"
              margin="normal"
              value={user_id}
              onChange={(e) => setUser_id(e.target.value)}
          />
        {/*<label>*/}
        {/*  ユーザーID:*/}
        {/*  <input*/}
        {/*    value={user_id}*/}
        {/*    onChange={e => setUser_id(e.target.value)}*/}
        {/*  />*/}
        {/*</label>*/}
        <p>ユーザーIDはユーザーネームと異なり各アカウントに割り付けられる変更不可能なIDで、メールに添付されています。</p>
          <TextField
              label="認証コード"
              variant="outlined"
              margin="normal"
              value={code}
              onChange={(e) => setCode(e.target.value)}
          />
        {/*<label>*/}
        {/*  認証コード:*/}
        {/*  <input*/}
        {/*    value={code}*/}
        {/*    onChange={e => setCode(e.target.value)}*/}
        {/*  />*/}
        {/*</label>*/}
        {
          code.length !== 12 &&
          <div>
            <p>認証コードを入力してください。</p>
            <p>認証コードは12桁のアルファベット、記号、数字によって構成された文字です。</p>
          </div>
          ||
          <br/>
        }
          {user_id !== '' && code.length === 12 &&
              <div>
                  <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
                      メールアドレス認証を開始する
                  </Button>
              </div>
          }
      </form>
      {/*{user_id !== '' && code.length === 12 &&*/}
      {/*  <div>*/}
      {/*      <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>*/}
      {/*          メールアドレス認証を開始する*/}
      {/*      </Button>*/}
      {/*  </div>*/}
      {/*}*/}
      <p>
        {log}
      </p>
    </div>
  )
}