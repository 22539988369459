import {shapedReblogStatusData, shapedStatus} from "../../../types";
import {Avatar, Box, Card, CardContent, Typography} from "@mui/material";
// @ts-ignore
import DOMPurify from 'dompurify';
import "./timeline_item.css"
import Toolbar from "./toolbar";

type Props = {
  status: shapedStatus
  favourite: (sns: string, post_id: string, isFavourite: boolean) => void
  reblog: (sns: string, post_id: string, isFavourite: boolean) => void
}
const TimelineItem: React.FC<Props> = ({status, favourite, reblog}) => {

  const contentWithBreaks = status.content.replace(/\n/g, '<br />');
  const sanitizedHTML = {__html: DOMPurify.sanitize(contentWithBreaks)};

  const ToolBarView = () => {
    switch (status.sns) {
      case 'neo':
        // ネオアスタルテの場合はリブログが他人のものであってもデフォルトでリブログ元が対象になるようにSNS側で制御されてる
        return (
          <Toolbar
            sns={status.sns}
            post_id={status.post_id}
            isFavourited={status.favourited}
            favourite={favourite}
            isReblog={status.reblog}
            reblog={reblog}
          />
        )
      case 'mastodon':
        //　mastodonはリブログ元が参照される仕様はないのでリブログがある場合リブログ先のIDを指定する必要がある。
        if (status.reblog_content === null) {
          // nullであればそのIDをそのまま返してしまって問題ない
          return (
            <Toolbar
              sns={status.sns}
              post_id={status.post_id}
              isFavourited={status.favourited}
              favourite={favourite}
              isReblog={status.reblog}
              reblog={reblog}
            />
          )
        } else {
          // リブログである場合はリブログ元を直接ファボれるように制御しておく必要がある。
          return (
            // <Toolbar
            //   sns={status.sns}
            //   post_id={status.reblog_content.post_id}
            //   isFavourited={status.reblog_content.favourited}
            //   favourite={favourite}
            // />
            <Toolbar
              sns={status.sns}
              post_id={status.post_id}
              isFavourited={status.favourited}
              favourite={favourite}
              isReblog={status.reblog}
              reblog={reblog}
            />
          )
        }
      default:

        // TypeScriptの使用に合わせてデフォルトを一応設定しておくが使われることはない
        return (
          <></>
        )
    }
  }

  // @ts-ignore
  return (
    <Card style={{marginBottom: '16px', width: '100%'}}>
      <CardContent>
        <Box display="flex" flexDirection="row">
          <Avatar src={status.account.icon_url || undefined}/>
          <Box ml={2} flexGrow={1}>
            <Typography variant="h6">
              {status.account.display_name || '設定されていません'}
            </Typography>
            <Typography variant="subtitle1">@{status.account.acct}</Typography>
          </Box>
        </Box>

        <Box mt={2}>
          <Typography variant="body1">
            <div dangerouslySetInnerHTML={sanitizedHTML}/>
          </Typography>
        </Box>

        {/* 画像の表示部分 */}
        {status.media && (
          <Box sx={{display: "flex", overflowX: "scroll", gap: "10px", marginTop: "10px"}}>
            {status.media.map((mediaItem) => (
              <a href={mediaItem.url} target="_blank">
                <img
                  key={mediaItem.media_id}
                  src={mediaItem.url}
                  alt=""
                  style={{maxWidth: '200px', maxHeight: '200px'}}
                />
              </a>
            ))}
          </Box>
        )}

        {status.reblog_content && (
          reblogItem(status.reblog_content)
        )}
        <Toolbar
          sns={status.sns}
          post_id={status.post_id}
          isFavourited={status.favourited}
          favourite={favourite}
          isReblog={status.reblog}
          reblog={reblog}
        />
      </CardContent>
    </Card>
  )
}

function reblogItem(data: shapedReblogStatusData) {

  const contentWithBreaks = data.content.replace(/\n/g, '<br />');
  const sanitizedHTML = {__html: DOMPurify.sanitize(contentWithBreaks)};

  return (
    <Box mt={2} border="1px solid #e1e4e8" borderRadius="5px" p={2}>
      <CardContent>
        <Box display="flex" flexDirection="row">
          <Avatar src={data.account.icon_url || undefined}/>
          <Box ml={2} flexGrow={1}>
            <Typography variant="h6">
              {data.account.display_name || '設定されていません'}
            </Typography>
            <Typography variant="subtitle1">@{data.account.acct}</Typography>
          </Box>
        </Box>

        <Box mt={2}>
          <Typography variant="body1">
            <div className="reblog__text" dangerouslySetInnerHTML={sanitizedHTML}/>
          </Typography>
        </Box>

        {/* 画像の表示部分 */}
        {data.media && (
          <Box sx={{display: "flex", overflowX: "scroll", gap: "10px", marginTop: "10px"}}>
            {data.media.map((mediaItem) => (
              <a href={mediaItem.url} target="_blank">
                <img
                  key={mediaItem.media_id}
                  src={mediaItem.url}
                  alt=""
                  style={{maxWidth: '200px', maxHeight: '200px'}}
                />
              </a>
            ))}
          </Box>
        )}
      </CardContent>
    </Box>
  )
}

export default TimelineItem