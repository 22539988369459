import {MenuItem, Select} from "@mui/material";
import React from "react";
import {useRecoilState} from "recoil";
import {timelineItemSetting} from "../../../Atoms";
import {writeStringData} from "../../../invoke/localStorage";

const TimeLineItemView = () => {

  const [timelineItemView, setTimelineItemView] = useRecoilState(timelineItemSetting);
  const handleChangeTimeLineItem = (event: { target: { value: string | ((currVal: string) => string); }; }) => {
    setTimelineItemView(event.target.value)
    if (event.target.value === "card" || event.target.value === "compress") {
      writeStringData("timeline_item_view", event.target.value)
    }
  }
  return (
    <>
      <h2>タイムラインの表示形態を変更することができます。</h2>
      <h3>圧縮タイムライン</h3>
      <p>余計な表示をカットすることでTLの情報量を増やします</p>
      <p>ただし、若干可視性が落ちる可能性があります。</p>
      <h3>カードタイムライン</h3>
      <p>タイムラインアイテムをカード形式にして表示し、見やすさが重視されたTL表示になります。</p>
      <p>ただし、TLの表示量が減ります</p>
      <div style={{marginBottom: '10px'}}>
        <Select
          labelId="timeline-view-select-label"
          id="timeline-view-select"
          value={timelineItemView}
          onChange={handleChangeTimeLineItem}
          fullWidth
        >
          <MenuItem value={"card"}>カードタイムライン</MenuItem>
          <MenuItem value={"compress"}>圧縮タイムライン</MenuItem>
        </Select>
      </div>
    </>
  )
}

export default TimeLineItemView