import './mobile.css'
import {Route, Routes, useNavigate} from "react-router-dom";
import {Setting} from "./view/setting/setting";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import React, {useState} from "react";
import Post from "./view/post/post";
import {useRecoilValue} from "recoil";
import {columnsAtom} from "./Atoms";
import Quick_post from "./quickPost";
import GamesMenu from "./view/games/gamesMenu";
import Public from "./view/neo/timeline/public/public";
import HomeAstarte from "./view/astarte/timeline/home";
import {reconnectFanctions} from "./types";


const Mobile: React.FC<reconnectFanctions> = ({reconnectAH, reconnectNP}) => {

  const columns = useRecoilValue(columnsAtom)
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  return (
    <>
      <AppBar position="fixed" style={{top: 'auto', bottom: 0}}>
        <Quick_post/>
        <BottomNavigation
          value={activeTab}
          onChange={(_event, newValue) => {
            navigate(columns[newValue]?.path, {replace: true});
            setActiveTab(newValue);
          }}
        >
          {columns.map(column => (
            <BottomNavigationAction key={column.id} label={column.name} value={column.id} icon={column.icon}/>
          ))}
        </BottomNavigation>
      </AppBar>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Public
                recconectNP={reconnectNP}
              />
            </div>
          }
        />
        <Route
          path="/home"
          element={
            <div className="m_canvas">
              ホームです！！！
            </div>
          }
        />
        <Route
          path='/public'
          element={
            <Public
              recconectNP={reconnectNP}
            />
          }
        />
        <Route
          path='/astarte/home'
          element={
            <HomeAstarte
              reconnectAH={reconnectAH}
            />
          }
        />
        <Route
          path="/new_post"
          element={
            <Post/>
          }
        />
        <Route
          path='/setting/*'
          element={
            <Setting/>
          }
        />
        <Route
          path='/games/*'
          element={
            <GamesMenu/>
          }
        />
      </Routes>
      {/* ここにコンテンツを追加 */}
    </>
  )
}

export default Mobile