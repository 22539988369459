import {loadAstarteToken} from "../../localStorage";
import axios from "axios";

async function reblogAstarte(post_id: string) {
  const loadAccessToken = await loadAstarteToken()
  if (loadAccessToken === 'no data') {
    return false
  }
  try {
    const response = await axios.post(`https://kirishima.cloud/api/v1/statuses/${post_id}/reblog`, {}
      , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': loadAccessToken
        }
      });
    console.log(response.status)
    if (response.status === 200) {
      return true
    }

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return false
      }
    } else {
      return false
    }
  }
  return false
}

async function unReblogAstarte(post_id: string) {
  const loadAccessToken = await loadAstarteToken()
  if (loadAccessToken === 'no data') {
    return false
  }
  try {
    const response = await axios.post(`https://kirishima.cloud/api/v1/statuses/${post_id}/unreblog`, {}
      , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': loadAccessToken
        }
      });
    console.log(response.status)
    if (response.status === 200) {
      return true
    }

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return false
      }
    } else {
      return false
    }
  }
  return false
}

export {
  reblogAstarte,
  unReblogAstarte
}