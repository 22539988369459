import {shapedReblogStatusData, shapedStatus} from "../../../types";
// @ts-ignore
import DOMPurify from 'dompurify';
import React from "react";
import "./timeline__item__compress.css"
import Toolbar from "./toolbar";
import {Box} from "@mui/material";

type Props = {
  status: shapedStatus
  favourite: (sns: string, post_id: string, isFavourite: boolean) => void
  reblog: (sns: string, post_id: string, isFavourite: boolean) => void
}

const TimeLineItemCompress: React.FC<Props> = ({status, favourite, reblog}) => {

  const contentWithBreaks = status.content.replace(/\n/g, '<br />');
  const sanitizedHTML = {__html: DOMPurify.sanitize(contentWithBreaks)};

  return (
    <div className="timeline__item__compress" >
      <div className="accounts__info">
        <img
          className="accounts__info__image"
          src={status.account.icon_url}
        />
        <div className="accounts__info__names">
          <div className="accounts__info__display__name">
            {status.account.display_name}
          </div>
          <div className="accounts__info__username">
            @{status.account.username}
          </div>
        </div>
      </div>
      <div className="status__contents">
        <div dangerouslySetInnerHTML={sanitizedHTML}/>
      </div>
      {status.media && (
        <Box sx={{display: "flex", overflowX: "scroll", gap: "10px", marginTop: "10px"}}>
          {status.media.map((mediaItem) => (
            <a href={mediaItem.url} target="_blank">
              <img
                key={mediaItem.media_id}
                src={mediaItem.url}
                alt=""
                style={{maxWidth: '200px', maxHeight: '200px'}}
              />
            </a>
          ))}
        </Box>
      )}
      {status.reblog_content && (
        reblogItem(status.reblog_content)
      )}
      <Toolbar
        sns={status.sns}
        post_id={status.post_id}
        isFavourited={status.favourited}
        favourite={favourite}
        isReblog={status.reblog}
        reblog={reblog}
      />
    </div>
  )
}

function reblogItem(data: shapedReblogStatusData) {
  const contentWithBreaks = data.content.replace(/\n/g, '<br />');
  const sanitizedHTML = {__html: DOMPurify.sanitize(contentWithBreaks)};
  return (
    <div className="timeline__reblog__item__compress" >
      <div className="accounts__info">
        <img
          className="accounts__info__image"
          src={data.account.icon_url}
        />
        <div className="accounts__info__names">
          <div className="accounts__info__display__name">
            {data.account.display_name}
          </div>
          <div className="accounts__info__username">
            @{data.account.username}
          </div>
        </div>
      </div>
      <div className="status__contents">
        <div dangerouslySetInnerHTML={sanitizedHTML}/>
      </div>
      {data.media && (
        <Box sx={{display: "flex", overflowX: "scroll", gap: "10px", marginTop: "10px"}}>
          {data.media.map((mediaItem) => (
            <a href={mediaItem.url} target="_blank">
              <img
                key={mediaItem.media_id}
                src={mediaItem.url}
                alt=""
                style={{maxWidth: '200px', maxHeight: '200px'}}
              />
            </a>
          ))}
        </Box>
      )}
    </div>
  )
}

export default TimeLineItemCompress