import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Collapse, List, ListItem, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

function GamesList() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List component="nav" aria-labelledby="nested-list-subheader">
            <ListItem button onClick={handleClick}>
                <ListItemText primary="ゲームメニュー" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button　onClick={()=>{
                        navigate('/games/gacha', { replace: true })
                    }}>
                        <ListItemText primary="ガチャを引く" />
                    </ListItem>
                </List>
            </Collapse>
        </List>
    )
}

export {
    GamesList
}