import {useEffect, useState} from "react";
import "./App.css";
import Mobile from "./mobile";
import Wide from "./wide";
import {
  Drawer,
  List,
  ListItem,
  Box, Hidden
} from '@mui/material';
import {useNavigate} from "react-router-dom";

import {useRecoilState, useRecoilValue} from "recoil";
import {
  accsessTokeAstarteAtom,
  accsessTokeAtom,
  columnsAtom,
  homeTimelineWebSocketAstarte,
  openDrawerAtom, publicTimelineWebSocketStatusNeoAstarte
} from "./Atoms";
import {loadNeoAstarteToken} from "./invoke/localStorage";
import useWebSocket from "./invoke/useWebSocket";

function App() {
  const [openDrawer, setOpenDrawer] = useRecoilState(openDrawerAtom)
  const columns = useRecoilValue(columnsAtom)
  const [accessToken, setAccessToken] = useRecoilState(accsessTokeAtom)
  const [accessTokenAstarte, setAccessTokenAstarte] = useRecoilState(accsessTokeAstarteAtom)
  // const setAccessToken = useSetRecoilState(accsessTokeAtom)
  const navigate = useNavigate();
  const [wsAH, setWsAH] = useRecoilState(homeTimelineWebSocketAstarte)
  const [wsNP, setWsNP] = useRecoilState(publicTimelineWebSocketStatusNeoAstarte)
  const {
    sendMessage: sendMessageAH,
    messages: messagesAH,
    isConnected: isConnectedAH,
    reconnect: reconnectAH
  } = useWebSocket(`wss://kirishima.cloud/api/v1/streaming/?stream=user&access_token=${accessTokenAstarte.substring(7)}`, 'astarte', 'home');
  const {
    sendMessage: sendMessageNP,
    messages: messagesNP,
    isConnected: isConnectedNP,
    reconnect: reconnectNP
  } = useWebSocket(`wss://neo.krsm.io/ws?token=${accessToken.substring(7)}`, 'neo', 'public');

  useEffect(() => {
    const loadData = async () => {

      const loadAccessToken = await loadNeoAstarteToken()
      if (loadAccessToken !== 'no data') {
        // @ts-ignore
        setAccessToken(loadAccessToken)
      }

      const loadAccessTokenAstarte = 'no data'
      if (loadAccessTokenAstarte !== 'no data') {
        // @ts-ignore
        setAccessTokenAstarte(loadAccessTokenAstarte)
      }

      if (loadAccessToken === 'no data' && loadAccessTokenAstarte === 'no data') {
        navigate('/setting', {replace: true});
      }

    }
    loadData()
  }, [])

  useEffect(() => {
    setWsAH(isConnectedAH)
  }, [isConnectedAH]);

  useEffect(() => {
    setWsNP(isConnectedNP)
  }, [isConnectedNP]);


  return (
    <Box style={{padding: '0px'}}>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem button onClick={() => {
            navigate('/setting', {replace: true});
          }}>
            設定
          </ListItem>
          <ListItem button onClick={() => {
            navigate('/', {replace: true});
          }}>
            デッキ
          </ListItem>
          {columns.map(column => (
            <ListItem key={column.id} button onClick={() => {
              setOpenDrawer(false);
            }}>
              {column.name}
            </ListItem>
          ))}
          <ListItem button onClick={() => {
            navigate('/astarte/home', {replace: true});
            setOpenDrawer(false);
          }}>ホーム(As)</ListItem>
          <ListItem button onClick={() => {
            navigate('/games', {replace: true});
            setOpenDrawer(false);
          }}>ゲーム</ListItem>
          <ListItem></ListItem>
        </List>
      </Drawer>

      {/*モバイルのデザイン*/}

      <Hidden mdUp implementation="css">
        <Mobile
          reconnectAH={reconnectAH}
          reconnectNP={reconnectNP}
        />
      </Hidden>

      {/*PCのデザイン*/}
      {/*<p>Last Message: {lastMessage ? lastMessage.data : 'No messages yet.'}</p>*/}
      <Hidden mdDown implementation="css">
        <Wide
          reconnectAH={reconnectAH}
          reconnectNP={reconnectNP}
        />
      </Hidden>
    </Box>
  );
}

export default App;
