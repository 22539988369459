import {useLocation} from "react-router-dom";
import {Hidden, IconButton, TextField} from "@mui/material";
import {useRecoilState, useRecoilValue} from "recoil";
import {accsessTokeAtom, post2sns, postDataAtom, postLocalOnly2Astarte} from "./Atoms";
import post_new_status from "./invoke/neo/statuses/new_status";
import React, {useState} from "react";
import SendIcon from '@mui/icons-material/Send';
import SnsIcon from "./snsIcon";
import {new_status_astarte} from "./invoke/astarte/statuses/new_status";

type neoAstartePostResult = {
  result: boolean
}

const QuickPost = () => {

  const location = useLocation();
  const accessToken = useRecoilValue(accsessTokeAtom)
  const [post, setPost] = useRecoilState(postDataAtom);
  const [waiting, setWaiting] = useState(false)
  const isLocalOnly = useRecoilValue(postLocalOnly2Astarte)
  const sns = useRecoilValue(post2sns)

  const handleChangePost = (event: { target: { value: string | ((currVal: string) => string); }; }) => {
    setPost(event.target.value)
  }

  const postButton = async () => {

    if (sns === "neo") {
      // 空白投稿を許可しない
      if (post !== "" && !waiting) {
        setWaiting(true)
        const status = await post_new_status(post, "public")
        if (status) {
          setPost("")
        }
        setWaiting(false)
      }
    } else if (sns === "mastodon") {
      // 空白投稿を許可しない
      if (post !== "" && !waiting) {
        setWaiting(true)
        let status
        if (isLocalOnly === "local") {
          status = await new_status_astarte(`${post} 👁️`, "public")
        } else {
          status = await new_status_astarte(post, "public")
        }
        if (status) {
          setPost("")
        }
        setWaiting(false)
      }
    }
  }

  if (location.pathname === '/new_post' || location.pathname.startsWith('/setting/') || location.pathname.startsWith('/game') || location.pathname === '/setting') {
    return (
      <></>
    );
  }

  return (
    <Hidden mdUp implementation="css">
      <div style={{display: 'flex', padding: '0px', justifyContent: 'space-between'}}>
        <div style={{maxHeight: 56, background: 'white'}}>
          <SnsIcon/>
        </div>
        <div style={{flexGrow: 1, display: 'flex'}}>
          <TextField
            label="Quick Post"
            variant="outlined"
            value={post}
            onChange={handleChangePost}
            sx={{
              background: 'white',
              flexGrow: 1,
            }}
          />
        </div>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => {
            postButton()
          }}
          sx={{
            flexGrow: 0,
            minWidth: 56,
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <SendIcon/>
        </IconButton>
      </div>
    </Hidden>

  )
}

export default QuickPost