import "./wide.css"
import {Route, Routes} from "react-router-dom";
import {Setting} from "./view/setting/setting";
import React from "react";
import Deck from "./view/neo/deck/deck";
import GamesMenu from "./view/games/gamesMenu";
import {reconnectFanctions} from "./types";

const Wide: React.FC<reconnectFanctions> = ({reconnectAH, reconnectNP}) => {

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Deck
                reconnectAH={reconnectAH}
                reconnectNP={reconnectNP}
            />
          }
        />
        <Route
          path='/setting/*'
          element={
            <Setting />
          }
        />
        <Route
          path='*'
          element={
            <Deck
                reconnectAH={reconnectAH}
                reconnectNP={reconnectNP}
            />
          }
        />
        <Route
          path='/games/*'
          element={
            <GamesMenu />
          }
        />
      </Routes>
    </>
  )
}

export default Wide