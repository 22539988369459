import {useRecoilState} from "recoil";
import {post2sns} from "./Atoms";
import astarte_icon from "./assets/astarte_icon.png"
import neo_icon from "./assets/neo_icon.png"
import React from "react";

const SnsIcon = () => {
  const [sns, setSns] = useRecoilState(post2sns)

  const handleChangeSNS = () => {
    if (sns === "neo") {
      setSns("mastodon")
    } else {
      setSns("neo")
    }
  }

  const Icons = () => {
    if (sns === "neo") {
      return(
        <img
          style={{width: 56, height: 56, borderRadius: 50, objectFit: 'cover'}}
          src={neo_icon}
        />
      )
    } else if (sns === "mastodon") {
      return (
        <img
          style={{width: 56, height: 56, borderRadius: 50, objectFit: 'cover'}}
          src={astarte_icon}
        />
      )
    } else {
      return(
        <img
          style={{width: 56, height: 56, borderRadius: 50, objectFit: 'cover'}}
          src={neo_icon}
        />
      )
    }
  }

  return (
    <div
      onClick={handleChangeSNS}
    >
      <Icons/>
    </div>
  )
}

export default SnsIcon