import "./toolbar.css"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from "@mui/icons-material/Star";
import RecyclingIcon from '@mui/icons-material/Recycling';
import ReplyIcon from '@mui/icons-material/Reply';
import {IconButton} from "@mui/material";
import React from "react";

type Props = {
  sns: string,
  post_id: string
  isFavourited: boolean,
  favourite: (sns: string, post_id: string, isFavourited: boolean) => void
  isReblog: boolean,
  reblog: (sns: string, post_id: string, isFavourited: boolean) => void
}
const ToolBar: React.FC<Props> = ({sns,post_id, isFavourited, favourite, isReblog, reblog}) => {

  const ReplyButton = () => {
    return (
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{mr: 2}}
        onClick={() => {
          favourite(sns, post_id, isFavourited)
        }}
      >
        <ReplyIcon/>
      </IconButton>
    )
  }
  const FavoriteButton = () => {
    switch (isFavourited) {
      case true:
        return (
          <IconButton
            size="large"
            edge="start"
            color="warning"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={() => {
              favourite(sns, post_id, isFavourited)
            }}
          >
            <StarIcon/>
          </IconButton>
        )
      case  false:
        return (
          <IconButton
            size="large"
            edge="start"
            color="default"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={() => {
              favourite(sns, post_id, isFavourited)
            }}
          >
            <StarIcon/>
          </IconButton>
        )
    }
  }

  const ReblogButton = () => {

    switch (isReblog) {
      case true:
        return (
          <IconButton
            size="large"
            edge="start"
            color="success"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={() => {
              reblog(sns, post_id, isReblog)
            }}
          >
            <RecyclingIcon/>
          </IconButton>
        )
      case  false:
        return (
          <IconButton
            size="large"
            edge="start"
            color="default"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={() => {
              reblog(sns, post_id, isReblog)
            }}
          >
            <RecyclingIcon/>
          </IconButton>
        )
    }
  }

  const BookmarkButton = () => {
    return (
      <IconButton
        size="large"
        edge="start"
        color="error"
        aria-label="menu"
        sx={{mr: 2}}
        onClick={() => {
          favourite(sns, post_id, isFavourited)
        }}
      >
        <BookmarkIcon/>
      </IconButton>
    )
  }

  const MenuButton = () => {
    return (
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{mr: 2}}
        onClick={() => {
          favourite(sns, post_id, isFavourited)
        }}
      >
        <MenuIcon/>
      </IconButton>
    )
  }

  return (
    <div className="tool__bar">
      <div className="tool__bar__icon">
        <ReplyButton/>
      </div>
      <div className="tool__bar__icon">
        <FavoriteButton/>
      </div>
      <div className="tool__bar__icon">
        <ReblogButton/>
      </div>
      <div className="tool__bar__icon">
        <BookmarkButton/>
      </div>
      <div className="tool__bar__icon">
        <MenuButton/>
      </div>

    </div>
  )
}

export default ToolBar