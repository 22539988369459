import {AppBar, Box, Hidden, IconButton, List, ListItem, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  accsessTokeAtom,
  openDrawerAtom,
  publicTimelineDataNeoAstarte,
  publicTimelineFetchStatus,
  publicTimelineNewestIdNeoAstarte,
  publicTimelineOldestIdNeoAstarte,
  publicTimelineWebSocketStatusNeoAstarte,
  timelineItemSetting
} from "../../../../Atoms";
import React, {useEffect} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import TimelineItem from "../timeline_item";
import {loadNeoAstarteToken} from "../../../../invoke/localStorage";
import axios from "axios";
import {transformResponseData} from "../../../../invoke/neo/statuses/transformPostData";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import {favouriteNeo, unfavouriteNeo} from "../../../../invoke/neo/statuses/favourite";
import TimeLineItemCompress from "../timeline__item__compress";
import {reblogNeo, unReblogNeo} from "../../../../invoke/neo/statuses/reblog";


type Props = {
  recconectNP: () => void
}

const Public: React.FC<Props> = ({recconectNP}) => {

  const [openDrawer, setOpenDrawer] = useRecoilState(openDrawerAtom)
  // const accessToken = useRecoilValue(accsessTokeAtom)
  const [accessToken, setAccessToken] = useRecoilState(accsessTokeAtom);
  const [items, setItems] = useRecoilState(publicTimelineDataNeoAstarte);
  const [fetch, setFetch] = useRecoilState(publicTimelineFetchStatus);
  const newestId = useRecoilValue(publicTimelineNewestIdNeoAstarte);
  const oldestId= useRecoilValue(publicTimelineOldestIdNeoAstarte);
  const wsNP = useRecoilValue(publicTimelineWebSocketStatusNeoAstarte)
  const timelineItemView = useRecoilValue(timelineItemSetting)


  useEffect(() => {
    const loadData = async () => {
      setItems([])
      if (accessToken === "") {
        const loadAccessToken = await loadNeoAstarteToken()
        if (loadAccessToken !== 'no data') {
          // @ts-ignore
          setAccessToken(loadAccessToken)
        }
      }

      const data = await loadNeoAstarteToken()
      if (!fetch) {
        setFetch(true)
        try {
          const response = await axios.get('https://neo.krsm.io/api/timelines/public', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': data
            }
          });
          if (response.status === 200) {
            const successData = transformResponseData(response.data)
            setItems(successData.data)
          }

        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 405 || error.response.status === 400 || error.response.status === 401) {

            }
          } else {

          }
        }
        setFetch(false)
      }
    }
    if (items.length <= 3) {
      loadData()
    }
  }, [])

  // 新しいデータを取得する関数
  const fetchData = async () => {

    if (accessToken === "") {
      const loadAccessToken = await loadNeoAstarteToken()
      if (loadAccessToken !== 'no data') {
        // @ts-ignore
        setAccessToken(loadAccessToken)
      }
    }

    const data = await loadNeoAstarteToken()
    if (!fetch && !wsNP) {
      setFetch(true)
      try {
        const response = await axios.get('https://neo.krsm.io/api/timelines/public', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': data
          },
          params: {
            'newest_id': newestId
          }
        });
        if (response.status === 200) {
          const successData = transformResponseData(response.data)
          // @ts-ignore
          setItems(items => [...successData.data, ...items])
        }

      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          if (error.response.status === 405 || error.response.status === 400 || error.response.status === 401) {

          }
        } else {

        }
      }
      setFetch(false)
    }
  };

  const loadMore = async () => {
    if (accessToken === "") {
      const loadAccessToken = await loadNeoAstarteToken()
      if (loadAccessToken !== 'no data') {
        // @ts-ignore
        setAccessToken(loadAccessToken)
      }
    }

    const data = await loadNeoAstarteToken()

    if (!fetch) {
      setFetch(true)
      try {
        const response = await axios.get('https://neo.krsm.io/api/timelines/public', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': data
          },
          params: {
            'oldest_id': oldestId
          }
        });
        if (response.status === 200) {
          const successData = transformResponseData(response.data)
          // @ts-ignore
          setItems(items => [...items, ...successData.data])
        }

      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          if (error.response.status === 405 || error.response.status === 400 || error.response.status === 401) {

          }
        } else {

        }
      }
      setFetch(false)
    }
  }

  const favourite = async (type: string, post_id: string, isFavourite: boolean) => {
    // 基本的にSNSがneo意外になることはないけど統合タイムラインを実装した時のための伏線的準備
    if (type === "neo") {
      if (isFavourite) {
        // すでにお気に入り登録されているならアンファボする
        const result = await unfavouriteNeo(post_id)
        if (result) {
          const newItems = items.map(item => {
            if (item.post_id === post_id) {
              return {...item, favourited: false}
            }
            return item
          })
          setItems(newItems)
        }
      } else {
        // まだお気に入り登録されていないのでファボる
        const result = await favouriteNeo(post_id)
        if (result) {
          const newItems = items.map(item => {
            if (item.post_id === post_id) {
              return {...item, favourited: true}
            }
            return item
          })
          setItems(newItems)
        }
      }
    }
  }

  const reblog = async (type: string, post_id: string, isReblog: boolean) => {
    // 基本的にSNSがneo意外になることはないけど統合タイムラインを実装した時のための伏線的準備
    if (type === "neo") {
      if (isReblog) {
        // すでにリブログされているのでリブログを解除する
        const result = await unReblogNeo(post_id)
        if (result) {
          const newItems = items.map(item => {
            if (item.post_id === post_id) {
              return {...item, reblog: false}
            }
            return item
          })
          setItems(newItems)
        }
      } else {
        // まだリブログされていないのでリブログする
        const result = await reblogNeo(post_id)
        if (result) {
          const newItems = items.map(item => {
            if (item.post_id === post_id) {
              return {...item, reblog: true}
            }
            return item
          })
          setItems(newItems)
        }
      }
    }
  }

  const ReconnectButton = () => {
    const handleClick = () => {
      if (!wsNP) {
        console.log("接続を再開します")
        recconectNP()
      } else {
        console.log("接続されています")
      }
    }
    switch (wsNP) {
      case true:
        return (
          <IconButton
            size="large"
            edge="start"
            color="default"
            aria-label="menu"
            sx={{mr: 2}}
          >
            <WifiIcon/>
          </IconButton>
        )
      case false:
        return (
          <IconButton
            size="large"
            edge="start"
            color="error"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={handleClick}
          >
            <WifiOffIcon/>
          </IconButton>
        )
    }
  }

  return (
    <>
      {/*<Hidden mdUp implementation="css">*/}
      <AppBar position="static" sx={{
        width: '100%'
      }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon

            />
          </IconButton>
          <Typography variant="h6" component="div" sx={{
            flexGrow: 1,
            overflow: 'hidden'
          }} noWrap>
            パブリックタイムライン
          </Typography>
          <ReconnectButton/>
        </Toolbar>
      </AppBar>
      {/*</Hidden>*/}

      {/*モバイルのデザイン*/}

      <Hidden mdUp implementation="css">
        <Box id="scrollableListMobile"
             style={{padding: '5px', height: 'calc(100vh - 132px - 85px)', margin: 'auto', overflowY: 'scroll'}}>
          <InfiniteScroll
            dataLength={items.length}
            next={loadMore}
            hasMore={true}
            scrollableTarget="scrollableListMobile"
            loader={<h4>Loading...</h4>}
            pullDownToRefresh={true}
            pullDownToRefreshContent={
              <div>/ｼｭｨｨｨｲｲｲｲｲｲｲｲﾝ\</div>
            }
            releaseToRefreshContent={
              <div>\ｼｭﾎﾟ/</div>
            }
            refreshFunction={fetchData}
            pullDownToRefreshThreshold={100}
            scrollThreshold={0.95}
          >
            <List>
              {items.map((item, index) => (
                <ListItem key={index}>
                  {timelineItemView === "card" ?
                    <TimelineItem
                      status={item}
                      favourite={favourite}
                      reblog={reblog}
                    />
                    :
                    <TimeLineItemCompress
                      status={item}
                      favourite={favourite}
                      reblog={reblog}
                    />
                  }
                </ListItem>
              ))}
            </List>
          </InfiniteScroll>
        </Box>
      </Hidden>

      {/*PCのデザイン*/}

      <Hidden mdDown implementation="css">
        <Box id="scrollableListWide"
             style={{padding: '5px', height: 'calc(100vh - 84px)', margin: 'auto', overflowY: 'scroll'}}>
          <InfiniteScroll
            dataLength={items.length}
            next={loadMore}
            hasMore={true}
            scrollableTarget="scrollableListWide"
            loader={<h4>Loading...</h4>}
            pullDownToRefresh={true}
            pullDownToRefreshContent={
              <div>/ｼｭｨｨｨｲｲｲｲｲｲｲｲﾝ\</div>
            }
            releaseToRefreshContent={
              <div>\ｼｭﾎﾟ/</div>
            }
            refreshFunction={fetchData}
            pullDownToRefreshThreshold={100}
            scrollThreshold={0.95}
          >
            <List>
              {items.map((item, index) => (
                <ListItem key={index}>
                  {timelineItemView === "card" ?
                    <TimelineItem
                      status={item}
                      favourite={favourite}
                      reblog={reblog}
                    />
                    :
                    <TimeLineItemCompress
                      status={item}
                      favourite={favourite}
                      reblog={reblog}
                    />
                  }
                </ListItem>
              ))}
            </List>
          </InfiniteScroll>
        </Box>
      </Hidden>

    </>
  )
}

export default Public;