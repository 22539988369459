import axios from "axios";
import {loadNeoAstarteToken} from "../../localStorage";

async function post_new_status (post: string, visibility: string = "public"): Promise<boolean> {

    const loadAccessToken = await loadNeoAstarteToken()
    if (loadAccessToken === 'no data') {
        return false
    }
    try {
        const response = await axios.post('https://neo.krsm.io/api/statuses/new_status', {
            content: post,
            visibility: visibility,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': loadAccessToken
            }
        });
        if (response.status === 200 ) {
            return true
        }

    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                return false
            }
        } else {
            return false
        }
    }
    return false
}

export default post_new_status