import {loadNeoAstarteToken} from "../../localStorage";
import axios from "axios";

async function favouriteNeo(post_id: string) {
  const loadAccessToken = await loadNeoAstarteToken()
  if (loadAccessToken === 'no data') {
    return false
  }
  try {
    const response = await axios.post('https://neo.krsm.io/api/statuses/favourite', {
      post_id: post_id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': loadAccessToken
      }
    });
    if (response.status === 200) {
      return true
    }

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return false
      }
    } else {
      return false
    }
  }
  return false
}

async function unfavouriteNeo(post_id: string) {
  const loadAccessToken = await loadNeoAstarteToken()
  if (loadAccessToken === 'no data') {
    return false
  }
  try {
    const response = await axios.post('https://neo.krsm.io/api/statuses/unfavourite', {
      post_id: post_id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': loadAccessToken
      }
    });
    if (response.status === 200) {
      return true
    }

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return false
      }
    } else {
      return false
    }
  }
  return false
}

export {
  favouriteNeo,
  unfavouriteNeo
}